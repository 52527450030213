import { Button, Row } from "antd";
import ArrowRight from "@/public/icons/ArrowRight.svg";
import Link from "next/link";
import dayjs from "dayjs";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

const CardBlog = (props) => {
  const { t } = useTranslation("common");
  var localizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(localizedFormat);

  const formatDate = (value) => {
    return useRouter().locale === "en"
      ? dayjs(value).format("LL").replace(",", "")
      : dayjs(value).format("DD/MM/YYYY");
  };

  const convertCategory = () => {
    const category = props.posts._embedded["wp:term"][0];
    return category[0].name || "";
  };

  const convertAuthorInfo = () => {
    const author = props.posts._embedded["author"][0];
    return {
      avatar:
        author.avatar_urls && author.avatar_urls[48]
          ? author.avatar_urls[48]
          : "",
      name: author.name || "",
    };
  };

  return (
    <div className="col-span-1 p-6 bg-white border border-solid border-grey">
      <div className="relative p-6 mb-6 group">
        <div className="absolute top-0 left-0 w-[14px] h-[14px] border-solid border-t-0 border-l-0 border-grey group-hover:translate-x-1 group-hover:translate-y-1 transition-transform"></div>
        <div className="absolute top-0 right-0 w-[14px] h-[14px] border-solid border-t-0 border-r-0 border-grey group-hover:-translate-x-1 group-hover:translate-y-1 transition-transform"></div>
        <div className="absolute bottom-0 left-0 w-[14px] h-[14px] border-solid border-l-0 border-b-0 border-grey group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform"></div>
        <div className="absolute bottom-0 right-0 w-[14px] h-[14px] border-solid border-r-0 border-b-0 border-grey group-hover:-translate-x-1 group-hover:-translate-y-1 transition-transform"></div>
        <img
          src={props.posts.thumbnail_link || ""}
          className="object-cover object-center w-full aspect-4/2"
          alt={props.posts.title.rendered || "CyStack thumbnail posts blog"}
        />
      </div>
      <div className="meta flex items-center gap-[6px] uppercase text-grey text-body5 leading-5 mb-3">
        <span>{formatDate(props.posts.modified)}</span>
        <div className="w-[3px] h-[3px] bg-grey"></div>
        <span>5 mins</span>
        <div className="w-[3px] h-[3px] bg-grey"></div>
        <span
          dangerouslySetInnerHTML={{ __html: convertCategory() || "" }}
        ></span>
      </div>
      <Link href={`/blog/${props.posts.slug}`}>
        <p
          className="mb-3 font-semibold cursor-pointer line-clamp-2 hover:underline text-dark-title text-h5"
          dangerouslySetInnerHTML={{ __html: props?.posts?.title?.rendered }}
        ></p>
      </Link>
      <div className="author flex items-center gap-[6px] uppercase text-grey text-body5">
        <img
          src={convertAuthorInfo().avatar}
          className="object-cover object-center overflow-hidden rounded-full w-7 h-7"
          alt="CyStack author post"
        />
        <div className="w-[3px] h-[3px] bg-grey"></div>
        <span>{t("by", { ns: "common" })}</span>
        <span className="font-medium text-dark-title">
          {convertAuthorInfo().name}
        </span>
      </div>
    </div>
  );
};

export default function SectionBlog(props) {
  const { t } = useTranslation("home");
  return (
    <>
      <section className="pt-20 pb-20 bg-white sm:pt-16 sm:pb-10">
        <div className="new-container">
          <h2 className="mb-12 text-center text-[61px] leading-[86px] lg:text-[47px] lg:leading-[64px]">
            {t("blog.title")}
          </h2>
          <Link href="/blog">
            <Button
              size={"large"}
              className="flex items-center gap-1 py-3 mx-auto text-white text-body1sb bg-neutral-dark hover:border-neutral-dark"
            >
              <span>{t("blog.button")}</span>
              <ArrowRight className="w-6 h-6 text-white" />
            </Button>
          </Link>
          <div className="grid grid-cols-3 gap-[22px] lg:grid-cols-2 md:grid-cols-1 mt-12">
            {props.posts &&
              props.posts.length ?
              props.posts.map((item) => (
                <CardBlog key={item.id} posts={item} />
              )) : null}
          </div>
        </div>
      </section>
    </>
  );
}
