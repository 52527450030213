import * as Sentry from "@sentry/nextjs";

import { getCookie, setCookie } from "cookies-next";

import SectionPartner from "@/components/Partner";
import Testimonial from "@/components/Testimonial";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import CyStackNghiDinh13 from "src/components/CyStackNghiDinh13";
import Banner from "src/components/home/Banner";
import SectionBlog from "src/components/home/Blog";
import SectionContact from "src/components/home/Contact";
import SectionFeatures from "src/components/home/Features";
import SectionPlatform from "src/components/home/Platform";
import SectionWhyUs from "src/components/home/WhyUs";
import resourceService from "../services/resources";
import axios from "axios";

export async function getServerSideProps({ locale }) {
  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale)),
    },
  };
}

export default function Home({ locale }) {
  const { t } = useTranslation("home");
  const { i18n } = useTranslation();
  const router = useRouter();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [posts, setPosts] = useState([]);

  const fetchPosts = async () => {
    try {
      const response = await axios.get(
        `/api/landingBlogPosts?locale=${locale}`
      );
      setPosts(response.data)
    } catch (error) {
      //
    }
  }

  useEffect(() => {
    fetchPosts()
  }, [locale])

  useEffect(() => {
    if (getCookie("is_visited")) {
      return;
    }
    resourceService
      .fetchCurrentCountry()
      .then((response) => {
        i18n.changeLanguage(response.language);
        setCookie("is_visited", true, { maxAge: 99999999 });
        router.push(router.pathname, router.asPath, {
          locale: response.language,
        });
      })
      .catch((error) => {
        Sentry.captureMessage(error);
      });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const is_showed_nd13 = sessionStorage.getItem("is_showed_nd13");
      if (is_showed_nd13) {
        return;
      }
      setIsOpenModal(true);
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: "body",
        nonce: undefined,
      }}
    >
      <Head>
        <title>{t("seo.title")}</title>
        <meta name="title" content={t("seo.title")} />
        <meta name="description" content={t("seo.desc")} />

        <meta property="og:title" content={t("seo.title")} />
        <meta property="og:description" content={t("seo.desc")} />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}${
            useRouter().locale === "vi" ? "/vi" : ""
          }${useRouter().asPath}`}
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />

        <meta name="twitter:title" content={t("seo.title")} />
        <meta name="twitter:description" content={t("seo.desc")} />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_SITE_URL}/ThumbnailCyStack.png`}
        />
      </Head>

      <Banner />

      <section className="bg-black">
        <div className="new-container">
          <div className="w-full"></div>
          <div className="flex justify-center py-16 md:flex-col md:justify-start md:items-center ">
            <div className="w-[335px]">
              <Lottie loop play path="/jsons/user.json" />
              <p className="text-center text-[20px] leading-[30px] text-white">
                {t("banner.customer")}
              </p>
            </div>
            <div className="w-[335px]">
              <Lottie loop play path="/jsons/solution.json" />
              <p className="text-center text-[20px] leading-[30px] text-white">
                {t("banner.security")}
              </p>
            </div>
            <div className="w-[335px]">
              <Lottie loop play path="/jsons/customer.json" />
              <p className="text-center text-[20px] leading-[30px] text-white">
                {t("banner.user")}
              </p>
            </div>
          </div>
        </div>
      </section>

      <SectionFeatures />
      <section className="home-partner">
        <SectionPartner />
      </section>

      <SectionPlatform />

      <SectionWhyUs />

      <Testimonial />

      <SectionContact />

      <SectionBlog posts={posts} />

      {/* <CyStackNghiDinh13
        isOpen={isOpenModal}
        closeModal={() => {
          sessionStorage.setItem("is_showed_nd13", true);
          setIsOpenModal(false);
        }}
      /> */}
    </GoogleReCaptchaProvider>
  );
}
