import ENDPOINT from "@/config/endpoint";
import request from "@/utils/request";

async function register(data) {
  return await request({
    url: ENDPOINT.REGISTER,
    method: "post",
    data: {
      ...data,
      source_link: window.location.href
    }
  });
}

export default {
  register
}